<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0371 6V10L13.0371 13M19.0371 10C19.0371 14.9706 15.0077 19 10.0371 19C5.06655 19 1.03711 14.9706 1.03711 10C1.03711 5.02944 5.06655 1 10.0371 1C15.0077 1 19.0371 5.02944 19.0371 10Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
